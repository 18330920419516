<template>
  <!-- 角色管理 -->
  <div class="container">
    <div class="head">
      <div class="dataOper">
        <!-- 查询 -->
        <div class="OperInput">
          <div class="search_box">
            <div class="search_main">
              <div class="status">
                <div>所属部门</div>
                <div>
                  <el-select placeholder="请选择部门" v-model="queryinfo.departmentId">
                  <el-option value="" label="全部"></el-option>
                    <el-option v-for="item in departmentlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div>成员状态</div>
                <div>
                  <el-select placeholder="启用" v-model="queryinfo.locked">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div>姓名</div>
                <div>
                  <el-input v-model="queryinfo.realname" placeholder="请输入内容"></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div>手机号</div>
                <div>
                  <el-input placeholder="请输入内容" v-model="queryinfo.mobile" style="width: 260px"></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div>账号</div>
                <div>
                  <el-input v-model="queryinfo.username" placeholder="请输入内容" style="width: 260px"></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status1" style="display: flex">
                <div class="demandBtn" @click="search">
                  <img src="../../../assets/images/search.png" alt="" /> 查询
                </div>
                <div class="resetBtn" @click="reset">
                  <img src="../../../assets/images/reset.png" alt="" />重置
                </div>

                <router-link to="/user/add">
                  <div class="addBtn" @click="dialogFormVisible = true">
                    <img src="../../../assets/images/add.png" style="margin-right: 5px;width: 13px;">增加员工
                  </div>
                </router-link>

              </div>
            </div>
          </div>
        </div>
        <!-- 查询 -->
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="body">
      <!-- 列表 -->
      <div class="dataTable">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;font-family: PingFangRoutine;" :header-cell-style="tableHeaderColor" :cell-style="{ color: '#2C2C2C', fontSize: '14px' }">
          <el-table-column prop="realname" label="成员姓名" align="center">
          </el-table-column>
          <el-table-column prop="roleName" label="角色名称" align="center">
          </el-table-column>
          <el-table-column prop="departmentName" label="所属部门" align="center">
          </el-table-column>
          <el-table-column prop="username" label="登录账号" align="center">
          </el-table-column>
          <el-table-column prop="mobile" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="qwName" label="企业微信" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="添加时间" align="center" />
          <el-table-column prop="freeze" label="冻结" align="center"  :formatter="formatBoolean"/>
          <el-table-column prop="locked" label="是否停用" align="center">
            <template v-slot:default="scope">
              <el-switch
                v-model="scope.row.locked" active-color="rgb(64,158,255)" inactive-color="rgb(138,138,138)" @change="changecomposestatus(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="220" align="center">
            <template #default="scope">
              <div class="operating">
                <div class="edit" @click="roleedit(scope.row.id)">
                  <img style="width: 18px;height: 18px;" src="../../../assets/images/edit.png"/>
                </div>
                <div class="delete" @click="userdel(scope.row.id)">
                  <i class="el-icon-delete"></i>
                </div>
                <div class="examine" v-if="scope.row.freeze" @click="unfreeze(scope.row.id)">
                   <i class="el-icon-lock"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 列表 -->
    </div>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
  </div>
</template>
<script>
import { userList, userStatus, userDelete, userFreeze } from "@/api/user";
import { departmentList } from "@/api/department";
import "../../../font-style/font.css";
import SysPerms from "@/views/system/user/sysPerms";
export default {
  components: {SysPerms},
  data() {
    return {
      queryinfo: {
       departmentId:"",
       locked:"",
       realname:"",
       username:"",
       mobile:"",
       currPage:""
      },
      options: [
        { id: 1, value: "false", label: "启用" },
        { id: 2, value: "true", label: "停用" },
      ],
      tableData: [],
      departmentlist: [],
      total: null,
    };
  },
  activated() {
    this.list();
  },
  methods: {
    formatBoolean(row, column, cellValue){
      if(cellValue){
        return "冻结";
      }else {
        return "活跃";
      }
    },
    //成员列表
    list() {
      userList(this.queryinfo).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;

        console.log(this.tableData);
      });
      //获取部门
      departmentList().then((res) => {
        this.departmentlist = res.data.list;
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },
    // table
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
      }
    },
    //成员状态修改
    changecomposestatus(val) {
      userStatus({ id: val.id }).then((res) => {
        if (res.data !== true) {
          return;
        }
        this.$notify({
          title: val.locked === true ? "停用" : "启用",
          message: val.locked === true ? "已停用！" : "已启用",
          type: "success",
        });
        this.list();
      });
    },
    //搜索
    search() {
      this.total=0
      this.queryinfo.currPage=""
      this.list();
    },
    // 重置
    reset() {
      this.total=0
      this.queryinfo.departmentId="",
      this.queryinfo.locked="",
      this.queryinfo.realname="",
      this.queryinfo.username="",
      this.queryinfo.mobile="",
      this.queryinfo.currPage=""
      this.list();
    },
    //删除
    async userdel(id) {
      if (!(await this._box())) return;
      userDelete({ id: id }).then((res) => {
        if (res.status !== 10000) {
          this.$notify({
            title: "警告",
            message: res.msg,
            type: "warning",
          });
          return;
        }
        let totalPage = Math.ceil((this.total - 1) / 10);
        let currentPage =
          this.queryinfo.currPage > totalPage
            ? totalPage
            : this.queryinfo.currPage;
        this.queryinfo.currPage = currentPage < 1 ? 1 : currentPage;
        this.list();
      });
    },
    //编辑
    roleedit(id) {
      this.$router.push({ path: "/user/edit", query: { userid: id } });
    },
    // 解冻
    unfreeze(id) {
      userFreeze({ id }).then((res) => {
        this.$notify({
          message: "操作成功",
          type: "success",
        });
        this.list();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/common.less";
/deep/ .head .el-card__body{
    padding-left: 0px;
  }
  .head{
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  }
 /deep/ .status>div:nth-child(1) {
    width: 57px;
    text-align: left;
  }
</style>
